// Our modules / classes

import Navigation from "./modules/Navigation";
import Slider from "./modules/Slider";
import RodoModal from "./modules/rodo";
import Animation from "./modules/Animations";
import Toggle from "./modules/Toggle";

// // Instantiate a new object using our modules/classes

const navigation = new Navigation();
const slider = new Slider();
const rodo = new RodoModal();
const animations = new Animation();
const toggle = new Toggle();

function handleFirstTab(e) {
	if (e.defaultPrevented) {
		return;
	}

	let key = e.key || e.keyCode;

	if (key === "Tab" || key === 9) {
		document.body.classList.add("user-is-tabbing");
		window.removeEventListener("keydown", handleFirstTab);
	}
}

window.addEventListener("keydown", handleFirstTab);

function _calculateBtnHeight() {
	let btnCall = document
		.querySelector("#show-phone-number")
		.getBoundingClientRect();

	document.documentElement.style.setProperty(
		"--btn-height",
		btnCall.height + "px"
	);
}

// recalculate on resize
window.addEventListener("resize", _calculateBtnHeight, false);

// recalculate on dom load
document.addEventListener("DOMContentLoaded", _calculateBtnHeight, false);

// recalculate on load (assets loaded as well)
window.addEventListener("load", _calculateBtnHeight);

// import Swiper JS
import Swiper from "swiper";
import { Keyboard, A11y, Pagination, Autoplay } from "swiper/modules";

class Slider {
	constructor() {
		const swiper = new Swiper(".swiper-info", {
			// Optional parameters
			modules: [Keyboard, A11y, Pagination, Autoplay],
			direction: "horizontal",
			loop: true,
			slidesPerView: 1,

			pagination: {
				el: ".swiper-pagination",
				dynamicBullets: false,
				clickable: true,
			},
			a11y: {
				prevSlideMessage: "Następny",
				nextSlideMessage: "Poprzedni",
			},
			keyboard: {
				enabled: true,
				onlyInViewport: false,
			},
			autoplay: {
				delay: 5000,
			},
			breakpoints: {
				640: {
					slidesPerView: 2,
				},
				768: {
					slidesPerView: 3,
				},
				1300: {
					slidesPerView: 4,
				},
			},
		});

		const gallery = new Swiper(".swiper-gallery", {
			// Optional parameters
			modules: [Keyboard, A11y, Pagination, Autoplay],
			direction: "horizontal",
			loop: true,
			slidesPerView: 1,

			pagination: {
				el: ".swiper-pagination",
				dynamicBullets: false,
				clickable: true,
			},
			a11y: {
				prevSlideMessage: "Następny",
				nextSlideMessage: "Poprzedni",
			},
			autoplay: {
				delay: 5000,
			},
			keyboard: {
				enabled: true,
				onlyInViewport: false,
			},
		});

		const plans = new Swiper(".swiper-plans", {
			// Optional parameters
			modules: [Keyboard, A11y, Pagination, Autoplay],
			direction: "horizontal",
			loop: true,
			slidesPerView: 1,

			pagination: {
				el: ".swiper-pagination",
				dynamicBullets: false,
				clickable: true,
			},
			a11y: {
				prevSlideMessage: "Następny",
				nextSlideMessage: "Poprzedni",
			},
			// autoplay: {
			// 	delay: 5000,
			// },
			keyboard: {
				enabled: true,
				onlyInViewport: false,
			},
		});
	}
}

export default Slider;

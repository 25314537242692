class Navigation {
	constructor() {
		if (document.getElementById("js-toggle")) {
			const nav = document.getElementById("navigation");
			const menuButton = document.getElementById("js-toggle");
			const menuOverlay = document.getElementById("js-overlay");
			const menuLinks = document.querySelectorAll("#js-toggled li");
			const firstItem = document.querySelector("#js-toggled li a");

			let focusedElementBeforeModal;

			function toggleOpen() {
				// Save current focus
				focusedElementBeforeModal = document.activeElement;

				// Listen for and trap the keyboard
				menuOverlay.addEventListener("keyup", trapTabKey);

				// Listen for indicators to close the modal
				window.onclick = function (event) {
					if (event.target.id === "js-overlay") {
						closeMenu();
					}
				};

				Array.from(menuLinks).forEach(function (element) {
					element.addEventListener("click", closeMenu);
				});

				// Find all focusable children
				let focusableElementsString =
					'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]';
				let focusableElements = menuOverlay.querySelectorAll(
					focusableElementsString
				);
				// Convert NodeList to Array
				focusableElements =
					Array.prototype.slice.call(focusableElements);

				let firstTabStop = focusableElements[0];
				let lastTabStop =
					focusableElements[focusableElements.length - 1];

				// Show the modal and overlay
				menuButton.classList.toggle("is-activated");
				menuOverlay.classList.toggle("is-open");

				// Focus element
				if (focusedElementBeforeModal === menuButton) {
					window.setTimeout(function () {
						firstItem.focus();
					}, 50);
				}

				function trapTabKey(event) {
					if (event.defaultPrevented) {
						return;
					}

					var key = event.key || event.keyCode;

					// Check for TAB key press
					if (key === "Tab" || key === 9) {
						// SHIFT + TAB
						if (event.shiftKey) {
							if (document.activeElement === firstTabStop) {
								event.preventDefault();
								lastTabStop.focus();
							}

							// TAB
						} else {
							if (document.activeElement === lastTabStop) {
								event.preventDefault();
								firstTabStop.focus();
							}
						}
					}

					// ESCAPE
					if (key === "Escape" || key === "Esc" || key === 27) {
						closeMenu();
					}
				}
			}

			function closeMenu() {
				menuButton.classList.remove("is-activated");
				menuOverlay.classList.remove("is-open");

				// Set focus back to element that had it before the modal was opened
				focusedElementBeforeModal.focus();
			}

			menuButton.addEventListener("click", function () {
				if (menuButton.classList.contains("is-activated")) {
					closeMenu();
				} else {
					toggleOpen();
				}
			});

			const changeNav = (entries, observer) => {
				entries.forEach((entry) => {
					// verify the element is intersecting
					if (entry.isIntersecting) {
						// remove old active class
						if (document.querySelector(".active-link")) {
							document
								.querySelector(".active-link")
								.classList.remove("active-link");
						}
						// get id of the intersecting section
						var id = entry.target.getAttribute("id");
						// find matching link & add appropriate class
						var newLink = document
							.querySelector(`[href="#${id}"]`)
							.classList.add("active-link");
					}
				});
			};

			var isLeaving = false;

			// init the observer
			const options = {
				threshold: 0,
				rootMargin: "-45% 0px -45% 0px",
			};

			const observer = new IntersectionObserver(changeNav, options);

			// target the elements to be observed
			const sections = document.querySelectorAll(".nav-section");
			sections.forEach((section) => {
				observer.observe(section);
			});

			// Stick menu

			const header = document.querySelector(".header");
			const sentinel = document.querySelector(".sticky-sentinel");

			const handler = (entries) => {
				if (!entries[0].isIntersecting) {
					header.setAttribute("stuck", "");
				} else {
					header.removeAttribute("stuck", "");
				}
			};

			// create the observer
			const observerSentinel = new window.IntersectionObserver(handler);
			// give the observer some dom nodes to keep an eye on
			observerSentinel.observe(sentinel);
		}
	}
}

export default Navigation;

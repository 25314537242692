import { gsap } from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";

class Animation {
	constructor() {
		gsap.registerPlugin(ScrollTrigger);

		// Hero line
		window.onload = function () {
			gsap.timeline().fromTo(
				".title span",
				{
					y: 400,
					opacity: 1,
					skewY: 7,
				},
				{
					y: 0,
					skewY: 0,
					opacity: 1,
					delay: 0.5,
					ease: "power4.out",
					duration: 1.8,
					stagger: {
						amount: 0.3,
					},
				}
			);
		};

		// Set mobile delays

		const mediaQuery = window.matchMedia("(max-width: 768px)");
		let delayValue;

		if (mediaQuery.matches) {
			delayValue = 0;
		} else {
			delayValue = 1;
		}

		// Animate h2s

		if (document.querySelector(".animate-heading")) {
			const animateHeadings =
				document.querySelectorAll(".animate-heading");

			animateHeadings.forEach((item) => {
				let element = gsap.utils.selector(item);
				let lines = element(".line span");
				const timelineHeading = gsap.timeline().from(lines, {
					y: 400,
					ease: "power4.out",
					skewY: 7,
					duration: 1.2,
					stagger: 0.3,
				});

				const STLeft = ScrollTrigger.create({
					animation: timelineHeading,
					trigger: item,
					start: "top 90%",
					//markers: true,
				});
			});
		}

		// Animate from bottom

		if (document.querySelector(".animate-bottom")) {
			const animateBottomTriggers =
				document.querySelectorAll(".animate-bottom");

			animateBottomTriggers.forEach((item) => {
				const animateBottom = gsap.timeline().fromTo(
					item,
					{
						y: 50,
						opacity: 0,
					},
					{
						y: 0,
						opacity: 1,
						duration: 1.2,
						delay: delayValue,
						ease: "power4.out",
					}
				);

				const STLeft = ScrollTrigger.create({
					animation: animateBottom,
					trigger: item,
					start: "top 90%",
					//markers: true,
				});
			});
		}

		// Animate map

		gsap.set("#punkty g", {
			transformOrigin: "50% 50%",
		});

		const animateMap = gsap.timeline().from("#punkty g", {
			scale: 0,
			opacity: 0,
			duration: 3,
			stagger: 0.2,
			ease: "power4.out",
		});

		const STLeft = ScrollTrigger.create({
			animation: animateMap,
			trigger: ".image-mapka",
			start: "top 90%",
			//markers: true,
		});
	}
}

export default Animation;

class RodoModal {
	constructor() {
		if (document.getElementById("js-rodo-toggle")) {
			const rodoButton = document.getElementById("js-rodo-toggle");
			const rodoOverlay = document.getElementById("js-rodo-overlay");
			const firstLink = document.querySelector("#js-toggled a");
			const rodoClose = document.querySelector(".rodo__btn--close");

			let focusedElementBeforeModal;

			function rodoOpen() {
				// Save current focus
				if (document.activeElement) {
					focusedElementBeforeModal = document.activeElement;
				}

				// Listen for and trap the keyboard
				rodoOverlay.addEventListener("keyup", trapKey);

				// Listen for indicators to close the modal

				window.addEventListener("click", function (event) {
					if (event.target.id === "js-rodo-overlay") {
						closeMenu();
					}
				});

				// Find all focusable children
				let focusableElementsString =
					'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]';
				let focusableElements = rodoOverlay.querySelectorAll(
					focusableElementsString
				);
				// Convert NodeList to Array
				focusableElements =
					Array.prototype.slice.call(focusableElements);

				let firstTabStop = focusableElements[0];
				let lastTabStop =
					focusableElements[focusableElements.length - 1];

				// Show the modal and overlay
				rodoButton.classList.toggle("is-activated");
				rodoOverlay.classList.toggle("is-open");

				// Focus element
				if (focusedElementBeforeModal === rodoButton) {
					window.setTimeout(function () {
						firstLink.focus();
					}, 50);
				}

				function trapKey(event) {
					if (event.defaultPrevented) {
						return;
					}

					var key = event.key || event.keyCode;

					// Check for TAB key press
					if (key === "Tab" || key === 9) {
						// SHIFT + TAB
						if (event.shiftKey) {
							if (document.activeElement === firstTabStop) {
								event.preventDefault();
								lastTabStop.focus();
							}

							// TAB
						} else {
							if (document.activeElement === lastTabStop) {
								event.preventDefault();
								firstTabStop.focus();
							}
						}
					}

					// ESCAPE
					if (key === "Escape" || key === "Esc" || key === 27) {
						closeMenu();
					}
				}
			}

			function closeMenu() {
				rodoButton.classList.remove("is-activated");
				rodoOverlay.classList.remove("is-open");

				// Set focus back to element that had it before the modal was opened
				focusedElementBeforeModal.focus();
			}

			rodoClose.addEventListener("click", function () {
				closeMenu();
			});

			rodoButton.addEventListener("click", function () {
				if (rodoButton.classList.contains("is-activated")) {
					closeMenu();
				} else {
					rodoOpen();
				}
			});
		}
	}
}

export default RodoModal;

import gsap from "gsap";

class Toggle {
	constructor() {
		function prepareToggle() {
			if (document.querySelector(".toggle__btn")) {
				const items = gsap.utils.toArray(".toggle__btn");

				let lastItem;

				let toggleWrapper =
					document.querySelectorAll(".toggle__wrapper");

				toggleWrapper.forEach((item, i) => {
					item.style.height = "0";
					item.style.visibility = "hidden";
				});

				items.forEach((item, i) => {
					item.tl = gsap
						.timeline({
							paused: true,
							onStart: () =>
								item.setAttribute("aria-expanded", true),
						})
						.to(item.nextElementSibling, {
							duration: 0.5,
							visibility: "visible",
							height:
								item.nextElementSibling.querySelector(
									".toggle__content"
								).scrollHeight + 10,
						});

					item.addEventListener("click", (e) => {
						// Reverse the other animations
						items.forEach((myItem) => myItem.tl.reverse());
						if (lastItem) {
							lastItem.setAttribute("aria-expanded", false);
						}

						if (
							typeof lastItem === "undefined" ||
							(lastItem && !lastItem.isSameNode(item))
						) {
							// Play the clicked item's animation
							item.tl.play();

							lastItem = item;
						} else {
							lastItem = undefined;
						}
					});
				});
			}
		}

		// recalculate on resize
		window.addEventListener("resize", prepareToggle, false);
		// recalculate on dom load
		document.addEventListener("DOMContentLoaded", prepareToggle, false);
		// recalculate on loaded styles
		window.addEventListener("load", prepareToggle, false);
	}
}

export default Toggle;
